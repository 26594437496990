import { Component } from 'solid-js'

interface ButtonProps {
	type?: 'button' | 'submit' | 'reset'
	text: string
	id?: string
	'aria-label'?: string
}

const Button: Component<ButtonProps> = props => {
	return (
		<button
			id={props.id ?? ''}
			aria-label={props['aria-label']}
			type={props.type ?? 'button'}
			tabindex="0"
			class="button button--primary">
			{props.text}
		</button>
	)
}

export default Button
